@import "../mainColors";
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: white;
  border-bottom: 1px solid $borderColor;
  box-shadow: 0px 1px 4px 0px rgba(9, 30, 66, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 0 18px;
}

@media screen and (max-width: 800px) {
  .main-header {
    height: 50px;
  }
}
