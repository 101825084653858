@import "./mainColors";

.ant-checkbox-wrapper {
  .ant-checkbox + span {
    padding: 0 16px;
    font-size: 13px;
  }

  & + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  .ant-checkbox-inner {
    border-color: $borderColor;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $blueButtonColor;
    }
  }

  .ant-checkbox-input:focus {
    border-color: $blueButtonColor;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ced4da;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $blueButtonColor;
      border-color: $blueButtonColor;
    }

    &:after {
      border-color: $blueButtonColor;
    }
  }
}

.ant-switch-checked {
  background-color: $brandColor !important;
}

.ant-click-animating-node {
  border: 0 solid $blueButtonColor
}

.ant-tree {
  .ant-tree-treenode-switcher-open:before, .ant-tree-treenode-switcher-close:before, .ant-tree-checkbox-inner {
    border-color: #ccc !important;
  }

  .ant-tree-checkbox {
    &.ant-tree-checkbox-checked {
      .ant-tree-checkbox-inner {
        background-color: $blueColor !important;
        border-color: $blueColor !important;

      }
    }

    &.ant-tree-checkbox-indeterminate {
      .ant-tree-checkbox-inner:after {
        background-color: $blueColor !important;
      }
    }
  }
}

.ant-tabs-tab {
  span {
    font-weight: normal !important;

    &.haveError {
      color: red;
    }
  }
}

.ant-picker{
  height: 35px;
  border-color: $borderColor!important;
  border-radius: 4px!important;
  &.ant-picker-focused{
    box-shadow: none;
  }
  .ant-picker-input {
    input{
      border: none!important;
    }
  }
  &.error {
    border: 1px solid red !important;
  }
}
.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    color:white;
  }
}

.date-picker {
  &.invalid {
    border-color: red!important;
    input {
      border-color: red!important;
    }
  }
}

.ant-select {
  .ant-select-arrow{
    transition: all 0.2s;
    transform: rotate(0);
  }

  &.ant-select-open{
    .ant-select-arrow{
      transform: rotate(180deg);
    }
  }

  &.disabled{
    .ant-select-selection{
      background: #ebebe4;
    }
  }
  &:focus, &:active, &:hover {
    box-shadow: none;
    border-color: $borderColor;
  }
  .ant-select-selector{
    border-color: $borderColor!important;
    box-shadow: none!important;
    border-radius: 4px!important;
    height: 35px!important;

    &.ant-select-focused:not(.ant-select-disabled){
      .ant-select:not(.ant-select-customize-input){
        box-shadow: none;
      }
    }
  }

}

.ant-time-picker-input {
  &:focus, &:active, &:hover {
    box-shadow: none !important;
    border-color: #a6a6a6 !important;
  }

  &:hover {
    border-color: $borderColor !important;
  }
}

.custom-notification {
  cursor: pointer;
}

.notification-success, .notification-error {
  z-index: 10000;
  .ant-notification-notice-message, .ant-notification-notice-description {
    color: white !important;
  }
  //ant-notification-close-icon
  .ant-notification-close-icon {
    color: white;
    svg{
      path{
        fill: white;
      }
    }

  }

  .ant-notification-notice-icon {
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.notification-success {
  background: #2cb76c !important;
}

.notification-error {
  background: #fb3838 !important;
}
.options{
  margin-top: 20px;
  .item-row{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .input-group{
      width: 92%;
      margin-bottom: unset;
    }
  }
}

.ant-picker-input > input:hover{
  border-color: transparent;
}
.ant-picker-input{
  input{
    border: unset!important;
  }
}
