@import "../mainColors";

$total-items: 20;
.left-side-menu {
  height: calc(100vh - 80px);
  margin-top: 80px;
  width: 55px;
  overflow-y: auto;
  -webkit-transition: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  transition: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

  &.open {
    width: 250px;
  }

  &.close {
    width: 20px;

    .menu-toggle-btn-wrapper {
      visibility: visible;
      left: 9px;
    }

    .aside-menu-list {
      visibility: hidden;
    }
  }

  .menu-toggle-btn-wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    left: 240px;
    top: 82px;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
    visibility: hidden;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    transition: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    -webkit-transition: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    transition-property: all;


    .menu-toggle-btn {
      font-size: 22px;
      z-index: 100;
      user-select: none;
    }

    &:hover {
      visibility: visible;
      background-color: rgb(76, 154, 255);
      color: white;
    }
  }

  .aside-menu-list {
    padding: 20px 8px 45px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    margin-bottom: 0;
    position: fixed;
    width: 250px;
    background: #FAFBFC;
    -webkit-transition: visibility 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
    transition: visibility 200ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &::-webkit-scrollbar {
      width: 0;
    }

    li {
      background: #FAFBFC;
      -webkit-transition: background .2s linear;
      transition: background .2s cubic-bezier(0.2, 0, 0, 1);
      cursor: pointer;

      > a {
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 8px 10px;
        border-radius: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;

        i {
          font-size: 24px;
          width: 24px;
          color: #06191d;
          margin-right: 16px;
          font-weight: 400;
          -webkit-transition: background .2s cubic-bezier(0.2, 0, 0, 1);
          transition: background .2s cubic-bezier(0.2, 0, 0, 1);
        }

        span {
          color: #06191d;
          font-size: 14px;
          -webkit-transition: background .2s cubic-bezier(0.2, 0, 0, 1);
          transition: background .2s cubic-bezier(0.2, 0, 0, 1);
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .badge {
          position: absolute;
          right: 8px;
          top: 10px;
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          //border: 1px solid $brandColor;
          background: $brandColor;
          color: white;
          font-weight: 600;
          border-radius: 4px;
          font-size: 12px;
        }

        &.active, &:hover {
          //background: linear-gradient(to right, $brandSecColor, $brandColor);
          background: $brandSecColor;

          i, span {
            color: $brandColor;
          }
          .badge {
            color: white;
          }
        }
      }
    }

    &:hover + .menu-toggle-btn-wrapper {
      visibility: visible;
    }
  }


}

@media screen and (max-width: 800px) {
  .left-side-menu {
    margin-top: 50px;

    &.open {
      width: 220px;



      .menu-toggle-btn-wrapper {
        left: 209px;
        top: 70px;
      }
    }
    .aside-menu-list {
      padding-top: 12px;
      width: 220px;
    }
  }
}
