@import "../mainColors";

.medias-list-wrapper {
  margin-bottom: 20px;

  .label-and-action {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    label {
      display: block;
      width: 100%;
      font-weight: 500;
      color: #000000;
      margin-bottom: 16px;
    }
  }

  .medias-list {
    border-radius: 4px;
    min-height: 100px;
    width: 100%;
    //border:1px solid $borderColor;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .nestable-group {
      display: flex;
      flex-wrap: wrap;
      .nestable-item{
        margin: 0;
      }
    }
    .media-card {
      max-width: 200px !important;
      width: 140px !important;
    }
    .empty-text {
      width: 100%;
      text-align: center;
    }
  }
}
